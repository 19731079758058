import React from "react";
import { Form, Formik } from "formik";
import { useSubmit } from "@hyper-fetch/react";

import { Container } from "components";
import { OptizmoSelector } from "./optizmo-selector/optizmo-selector";
import { EspSelector } from "./esp-selector/esp-selector";
import { OptizmoExportData } from "./optizmo-export.types";
import { initialValues, validationSchema } from "./optizmo-export.constants";
import { createOfferMd5s, MatchMd5sData } from "api";

import styles from "./optizmo-export.module.scss";

export const OptizmoExportPage: React.FC = () => {
  const { submit } = useSubmit(createOfferMd5s);

  const handleSubmit = async (values: OptizmoExportData) => {
    const { offerId, espData } = values;
    if ("fromListType" in espData) delete espData.fromListType;
    if ("toListType" in espData) delete espData.toListType;

    if (espData.esp === "iterable") {
      const { accountId, ...rest } = espData;
      const data: MatchMd5sData = { offerId, espData: rest };

      await submit({ data });
    }

    if (espData.esp === "salesforce") {
      const data: MatchMd5sData = { offerId, espData };
      await submit({ data });
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className={styles.wrapper}>
                <OptizmoSelector />
                <EspSelector />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
