import React, { useState } from "react";
import { FormField, Label, SelectOption, Loader } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSubmit } from "@hyper-fetch/react";

import { TabOption, Tabs } from "components";
import { OptizmoExportData, SalesforceData, SalesforceDataExtensionData } from "pages/export/optizmo-export.types";
import { ListMode } from "../salesforce-selector.types";
import { listModeOptions } from "../salesforce-selector.constants";
import { getDataExtensionFields } from "api";

import styles from "pages/export/optizmo-export.module.scss";

export type ToListProps = {
  dataExtensionsOptions: SelectOption<string>[];
  dataExtensionsLoading?: boolean;
  listsOptions: SelectOption<number>[];
  listsLoading?: boolean;
};

export const ToList: React.FC<ToListProps> = ({
  dataExtensionsOptions,
  dataExtensionsLoading,
  listsOptions,
  listsLoading,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<OptizmoExportData>();
  const espData = values.espData as SalesforceData;

  const [fieldsLoading, setFieldsLoading] = useState<boolean>(false);
  const [fieldOptions, setFieldOptions] = useState<SelectOption<string>[]>([]);

  const dataExtensionFields = useSubmit(getDataExtensionFields);
  dataExtensionFields.onSubmitRequestStart(() => {
    // we need to handle it manually since we have 2 request on same page and there's problem with caching
    setFieldsLoading(true);
  });
  dataExtensionFields.onSubmitFinished(() => {
    setFieldsLoading(false);
  });
  dataExtensionFields.onSubmitSuccess(({ response }) => {
    if (response) {
      const options: SelectOption<string>[] = response.map((field) => ({
        label: field.Name,
        value: field.Name,
      }));
      setFieldOptions(options);
    }
  });

  const handleTabChange = async ({ value }: TabOption<ListMode>) => {
    await setFieldValue("espData.toListType", value);
  };

  const handleNameChange = async (option: SelectOption | null) => {
    // we only need to set the name - id is set automatically from FormField
    await setFieldValue("espData.toList.name", option ? option.label : "");
    setTimeout(() => setFieldTouched("espData.toList.name", true));
  };

  useDidUpdate(() => {
    // fetch data extension fields
    if (espData.businessUnitId && espData.toListType === "dataExtension") {
      const data = espData.toList as SalesforceDataExtensionData;

      if (data.customerKey) {
        dataExtensionFields.submit({
          data: { customerKey: data.customerKey },
          params: { businessUnitId: espData.businessUnitId },
        });
      }
    }
  }, [espData.businessUnitId, espData.toListType, (espData.toList as SalesforceDataExtensionData)?.customerKey]);

  return (
    <div>
      <div style={{ marginBottom: "24px" }}>
        <div className={styles.row}>
          <div className={styles.step}>7</div>
          <div>
            <Label text="To list type" isInputLabel />
            <p className={styles.projectDescription}>Select a to list</p>
          </div>
        </div>

        <Tabs selected={espData.toListType || "dataExtension"} onChange={handleTabChange} options={listModeOptions} />
      </div>

      <div>
        <div>
          <p className={styles.projectDescription}>
            Select a {espData.toListType === "dataExtension" ? "data extension" : "list"}
          </p>
        </div>

        {espData.toListType === "list" && (
          <FormField
            type="select"
            name="espData.toList.id"
            onChange={handleNameChange}
            options={listsOptions}
            isSearchable
            searchPlaceholder="Search lists"
            disabled={!espData?.businessUnitId || listsLoading}
            asyncOptions={{
              loading: listsLoading,
            }}
            isMulti={false}
          />
        )}

        {espData.toListType === "dataExtension" && (
          <FormField
            type="select"
            name="espData.toList.customerKey"
            options={dataExtensionsOptions}
            isSearchable
            onChange={handleNameChange}
            searchPlaceholder="Search data extensions"
            disabled={!espData?.businessUnitId || dataExtensionsLoading}
            asyncOptions={{
              loading: dataExtensionsLoading,
            }}
            isMulti={false}
          />
        )}

        {espData.toListType === "dataExtension" &&
          !!espData.businessUnitId &&
          !!(espData.toList as SalesforceDataExtensionData).customerKey && (
            <div>
              <p className={styles.projectDescription}>Select an email field for this data extension:</p>

              {fieldsLoading && <Loader type="clip-loader" />}
              {!fieldsLoading && fieldOptions.length === 0 && (
                <p className={styles.noContentText}>No fields to display</p>
              )}
              {!fieldsLoading && fieldOptions.length > 0 && (
                <div className={styles.column}>
                  <FormField
                    type="radio"
                    name="espData.toList.emailProperty"
                    options={fieldOptions}
                    disabled={!espData?.businessUnitId}
                  />
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};
