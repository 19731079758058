import React, { useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { GetJobsQuery, getMd5Jobs } from "api";
import { getInitialStorageFilters } from "utils";
import { initialFilters, TABLE_NAME, md5JobsColumns } from "./md5-jobs-list.constants";
import { Md5JobModel, List } from "models";
import { useTablePagination } from "hooks";
import { Container, TableError, TableNoContent } from "components";
import { Md5JobItem } from "./md5-job-item/md5-job-item";

import styles from "pages/jobs/list/jobs-list.module.scss";

export const Md5JobsListPage: React.FC = () => {
  const { query, searchValue, setSearch } = useFilters<GetJobsQuery>(
    getInitialStorageFilters<GetJobsQuery>(TABLE_NAME, initialFilters),
  );

  const [jobs, setJobs] = useState<List<Md5JobModel> | null>(null);

  const { loading, refetch, error, onSuccess } = useFetch(getMd5Jobs.setQueryParams(query));
  onSuccess(({ response }) => setJobs(response));

  const pagination = usePagination({ listPayload: jobs });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  return (
    <Container>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} />
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={md5JobsColumns}
        list={jobs?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(job) => <Md5JobItem job={job} />}
      />
    </Container>
  );
};
