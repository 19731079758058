import React from "react";
import { useFetch } from "@hyper-fetch/react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { BreadcrumbsOption, Container, TableError, TableNoContent } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination } from "hooks";
import { CompanyModel } from "models";
import { getCompanies } from "api";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { filtersOptions, TABLE_NAME, optizmoCompaniesColumns } from "./optizmo-companies.constants";
import { DeleteResponse } from "api/api.types";
import { OptizmoCompanyItem } from "./table-item/optizmo-company-item";
import { CreateOptizmoCompany, UpdateOptizmoCompany } from "pages/integrations/optizmo";
import { OPTIZMO_COMPANIES_LIST_PAGE } from "constants/routes.constants";
import { Plus } from "assets";

import styles from "./optizmo-companies.module.scss";

export const OptizmoCompaniesPage: React.FC = () => {
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create: createAccount } = useItemManagement<CompanyModel>();
  const { update } = useItemManagement<CompanyModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("optizmo", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "accounts");

  const { data, loading, refetch, error, setData } = useFetch(
    getCompanies.setQueryParams({ ...query, search: query.search }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (account: CompanyModel) => {
    update.setItem(account);
  };

  const handleCreateAccountSuccess = (account: CompanyModel) => {
    if (data) addItem(account, data, setData);
  };

  const handleUpdateAccountSuccess = (account: CompanyModel) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const breadcrumbs: BreadcrumbsOption[] = [{ name: "Optizmo companies", path: OPTIZMO_COMPANIES_LIST_PAGE.path }];

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="Optizmo companies"
        description="List of all Optizmo companies. Click on item to see all clients added to this company."
        navbarClassName="optizmo-navbar"
        breadcrumbs={breadcrumbs}
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && (
              <Button onClick={createAccount.open} className="add-optizmo-company">
                <Plus /> Add Optizmo company
              </Button>
            )}
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={optizmoCompaniesColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(company, index) => (
              <OptizmoCompanyItem
                index={index}
                company={company}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
              />
            )}
          />
          <CreateOptizmoCompany
            isOpen={createAccount.isOpen}
            close={createAccount.close}
            refresh={refetch}
            onCreateSuccess={handleCreateAccountSuccess}
          />
          <UpdateOptizmoCompany
            company={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateAccountSuccess}
          />
        </div>
      </Container>
    </>
  );
};
