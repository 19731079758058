import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  Switch,
  useQuery,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "hooks";
import { deleteOffer, updateOffer } from "api";
import { DeleteResponse } from "api/api.types";
import { Accounts } from "assets";
import { OfferModel } from "models";

import styles from "./optizmo-offer-item.module.scss";

type OptizmoCompanyItemProps = {
  index: number;
  offer: OfferModel;
  handleEdit: (company: OfferModel) => void;
  onRemoveSuccess: (response: DeleteResponse) => void;
  onActivateSuccess: (company: OfferModel) => void;
};

export const OptizmoOfferItem: FC<OptizmoCompanyItemProps> = ({
  index,
  offer,
  handleEdit,
  onRemoveSuccess,
  onActivateSuccess,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const [canUpdateAccount, canDeleteAccount] = usePermissions(["update", "delete"], "accounts");

  const remove = useSubmit(deleteOffer);
  remove.onSubmitSuccess(({ response }) => {
    onRemoveSuccess(response);
    notification.success("Optizmo offer deleted", "Optizmo offer successfully deleted");
  });
  remove.onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Optizmo offer.");
  });

  const update = useSubmit(updateOffer);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Optizmo offer activated" : "Optizmo offer deactivated",
      response.isActive ? "Optizmo offer is now active." : "Optizmo offer is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the company");
  });

  const handleRemoveItem = () => {
    notification.confirm("Do you want to delete this Optizmo offer?", "This action is irreversible!", {
      onOk: async () => {
        await remove.submit({ params: { offerId: offer.id } });
      },
    });
  };

  const handleEditItem = () => handleEdit(offer);

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { offerId: offer.id } });
  };

  return (
    <TableRow id={offer.id} className={styles.row}>
      <TableCell className={styles.name}>
        <Accounts style={{ marginRight: "8px" }} />
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {offer.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {offer.accessKey}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch value="toggle" checked={offer.isActive} disableError onChange={handleSwitchChange} />
        </div>
      </TableCell>

      <TableCell>
        <div ref={menuRef} data-testid={`iterable-menu-button-${index}`}>
          <Menu portalClassName={`iterable-menu-${index}`}>
            {canUpdateAccount && (
              <MenuButton onClick={handleEditItem} icon="edit" data-testid={`iterable-edit-${index}`}>
                Edit
              </MenuButton>
            )}
            {canDeleteAccount && (
              <MenuButton
                appearance="delete"
                onClick={handleRemoveItem}
                icon="delete"
                data-testid={`iterable-delete-${index}`}
              >
                Delete
              </MenuButton>
            )}
            {!canUpdateAccount && !canDeleteAccount && <div style={{ padding: "10px" }}>No Actions available</div>}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};
