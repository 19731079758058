import { client } from "api/client.api";
import { ClientModel, List } from "models";
import { ClientData, UpdateClientData } from "./clients.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";

export const createClient = client.createRequest<ClientModel, ClientData>()({
  method: "POST",
  endpoint: "/clients",
});

export const getClients = client.createRequest<
  List<ClientModel>,
  null,
  ServerErrorType,
  BaseSearchQuery & BasePaginationQuery & { companyId?: string }
>()({
  method: "GET",
  endpoint: "/clients",
  options: { timeout: 0 },
});

export const getActiveClients = client.createRequest<
  ClientModel[],
  null,
  ServerErrorType,
  Partial<BaseSearchQuery & BasePaginationQuery & { companyId: string }>
>()({
  method: "GET",
  endpoint: "/clients/active",
  options: { timeout: 0 },
});

export const getClient = client.createRequest<ClientModel, null, ServerErrorType>()({
  method: "GET",
  endpoint: "/clients/:clientId",
  options: { timeout: 0 },
});

export const updateClient = client.createRequest<ClientModel, UpdateClientData>()({
  method: "PUT",
  endpoint: "/clients/:clientId",
});

export const deleteClient = client.createRequest<DeleteResponse>()({
  endpoint: "/clients/:clientId",
  method: "DELETE",
});
