import React, { useState } from "react";

import { Container } from "components";
import { OfferSelector } from "./offer-selector/offer-selector";
import { Md5FileImport } from "./md5-file-import/md5-file-import";

import styles from "./md5-import.module.scss";

export const Md5ImportPage: React.FC = () => {
  const [offerId, setOfferId] = useState<number>(0);

  return (
    <Container className={styles.wrapper}>
      <div className={styles.container}>
        <OfferSelector offerId={offerId} onOfferChange={setOfferId} />
        <Md5FileImport offerId={offerId} />
      </div>
    </Container>
  );
};
