import React, { useMemo, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";

import { ToList } from "./to-list/to-list";
import { FromList } from "./from-list/from-list";
import { getActiveBusinessUnits, getActiveLists, getAllActiveDataExtensions } from "api";
import {
  OptizmoExportData,
  SalesforceData,
  SalesforceDataExtensionData,
  SalesforceListData,
} from "pages/export/optizmo-export.types";
import { initialSalesforceDataExtensionData, initialSalesforceListData } from "pages/export/optizmo-export.constants";

import styles from "../../optizmo-export.module.scss";

export const SalesforceSelector: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<OptizmoExportData>();

  const [businessUnitsOptions, setBusinessUnitsOptions] = useState<SelectOption<number>[]>([]);
  const [dataExtensionsOptions, setDataExtensionsOptions] = useState<SelectOption<string>[]>([]);
  const [listsOptions, setListsOptions] = useState<SelectOption<number>[]>([]);

  const espData = values.espData as SalesforceData;

  const activeBusinessUnits = useSubmit(getActiveBusinessUnits);
  activeBusinessUnits.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((businessUnits) => ({
      label: businessUnits.name,
      value: businessUnits.id,
    }));
    setBusinessUnitsOptions(options);
  });

  const activeDataExtensions = useSubmit(getAllActiveDataExtensions);
  activeDataExtensions.onSubmitSuccess(({ response }) => {
    const options: SelectOption<string>[] = response.map((dataExtension) => ({
      label: dataExtension.Name,
      value: dataExtension.CustomerKey,
    }));
    setDataExtensionsOptions(options);
  });

  const activeLists = useSubmit(getActiveLists);
  activeLists.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((dataExtension) => ({
      label: dataExtension.ListName,
      value: dataExtension.ID,
    }));
    setListsOptions(options);
  });

  useDidMount(() => {
    activeBusinessUnits.submit();
  });

  useDidUpdate(() => {
    // reset fromList and toList based on listType
    if (espData.fromListType === "dataExtension") setFieldValue("espData.fromList", initialSalesforceDataExtensionData);
    if (espData.fromListType === "list") setFieldValue("espData.fromList", initialSalesforceListData);

    if (espData.toListType === "dataExtension") setFieldValue("espData.toList", initialSalesforceDataExtensionData);
    if (espData.toListType === "list") setFieldValue("espData.toList", initialSalesforceListData);

    if (espData.businessUnitId) {
      activeDataExtensions.submit({ params: { businessUnitId: espData.businessUnitId.toString() } });
      activeLists.submit({ params: { businessUnitId: espData.businessUnitId.toString() } });
    }
  }, [espData.businessUnitId]);

  const filteredFromListsOptions = useMemo(() => {
    const fromListData = espData.toList as SalesforceListData;
    if (fromListData?.id) return listsOptions.filter((option) => option.value !== fromListData.id);

    return listsOptions;
  }, [espData.toList, listsOptions]);

  const filteredToListsOptions = useMemo(() => {
    const toListData = espData.fromList as SalesforceListData;
    if (toListData?.id) return listsOptions.filter((option) => option.value !== toListData.id);
    return listsOptions;
  }, [espData.fromList, listsOptions]);

  const filteredFromDataExtensionOptions = useMemo(() => {
    const fromListData = espData.toList as SalesforceDataExtensionData;
    if (fromListData?.customerKey)
      return dataExtensionsOptions.filter((option) => option.value !== fromListData.customerKey);

    return dataExtensionsOptions;
  }, [espData.toList, dataExtensionsOptions]);

  const filteredToDataExtensionOptions = useMemo(() => {
    const toListData = espData.fromList as SalesforceDataExtensionData;
    if (toListData?.customerKey)
      return dataExtensionsOptions.filter((option) => option.value !== toListData.customerKey);
    return dataExtensionsOptions;
  }, [espData.fromList, dataExtensionsOptions]);

  return (
    <div style={{ marginTop: "26px" }}>
      <div>
        <div className={styles.row}>
          <div className={styles.step}>5</div>
          <div>
            <Label text="Business units to suppress" isInputLabel />
            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
          </div>
        </div>
        <FormField
          type="select"
          name="espData.businessUnitId"
          options={businessUnitsOptions}
          isSearchable
          asyncOptions={{
            loading: activeBusinessUnits.submitting,
          }}
          searchPlaceholder="Search accounts"
          isMulti={false}
        />
      </div>

      <div className={styles.selectGrid}>
        <FromList
          dataExtensionsOptions={filteredFromDataExtensionOptions}
          listsOptions={filteredFromListsOptions}
          listsLoading={activeLists.submitting}
          dataExtensionsLoading={activeDataExtensions.submitting}
        />
        <ToList
          dataExtensionsOptions={filteredToDataExtensionOptions}
          listsOptions={filteredToListsOptions}
          listsLoading={activeLists.submitting}
          dataExtensionsLoading={activeDataExtensions.submitting}
        />
      </div>
    </div>
  );
};
