import { FC, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, Modal, MessageField, FormButtons, useFileParser } from "@epcnetwork/core-ui-kit";

import { FormField as LocalFormField } from "components";
import { validationSchema } from "pages/md5-import/md5-import-values/md5-import-values.constants";
import { Md5Values, Md5FileItem } from "./md5-import-values.types";

import styles from "../md5-import.module.scss";

type Md5ImportValuesProps = {
  file: Md5FileItem;
  onCloseClick: VoidFunction;
  onSubmitClick: (fileValues: Md5FileItem) => void;
};

export const Md5ImportValues: FC<Md5ImportValuesProps> = ({ file, onCloseClick, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<Md5Values>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      emailIndex: file.data.emailIndex,
      headers: file.data.headers,
    },
  });

  const onSubmit = (values: Md5Values, { setSubmitting }: FormikHelpers<Md5Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...values,
        ...file.data,
        ...values.headerValues,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={onCloseClick} contentClassName={`${styles.modal} md5-import-modal-content`}>
      <h2 className={styles.title}>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <Form>
          <LocalFormField
            type="file-table"
            name="headerValues"
            label="Select Email column"
            parsedFile={parsedFile}
            loading={loading}
            selector="radio"
            autocompleteColumns={false}
          />
          <FormButtons>
            <Button appearance="secondary" onClick={onCloseClick}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
