import React from "react";
import { Button, formatDate, TableCell, TableRow, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { Calendar, Clock, CrossCircle } from "assets";
import { Md5JobModel } from "models";

import styles from "./md5-job-item.module.scss";

interface Props {
  job: Md5JobModel;
}

export const Md5JobItem: React.FC<Props> = ({ job }) => {
  const createdAtDate = job.createdAt;

  return (
    <TableRow id={job.id} className={styles.tableRow}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.createdAt}
        </TextEllipsis>
      </TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>
        {/*{job.status === "inProgress" && job?.finishedCount ? (*/}
        {/*    <Progress current={job.finishedCount} total={job.valuesCount} />*/}
        {/*) : (*/}
        {/*    <JobItemStatus status={job.status} valuesCount={job.valuesCount} />*/}
        {/*)}*/}
      </TableCell>

      <TableCell>
        {/*{canTerminateJob && job.status === "inProgress" && (*/}
        {/*    <div ref={terminateButtonRef}>*/}
        {/*        <Button*/}
        {/*            btnSize="small"*/}
        {/*            onClick={handleTerminateJob}*/}
        {/*            appearance="primary"*/}
        {/*            className={styles.terminateButton}*/}
        {/*        >*/}
        {/*            <CrossCircle />*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*)}*/}
      </TableCell>
    </TableRow>
  );
};
