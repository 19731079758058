import React, { useState } from "react";
import { Button, Label, Select, SelectOption } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useFetch } from "@hyper-fetch/react";

import { getActiveDataExtensions, getActiveProjects } from "api";
import { BUSINESS_UNIT_DETAILS_PAGE, ITERABLE_ACCOUNTS_LIST_PAGE } from "constants/routes.constants";
import { Plus } from "assets";

import styles from "./lists-select.module.scss";

interface Props {
  selectedProjects: number[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<number[]>>;
  selectedDataExtensions: number[];
  setSelectedDataExtensions: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ListsSelect: React.FC<Props> = ({
  selectedProjects,
  setSelectedProjects,
  selectedDataExtensions,
  setSelectedDataExtensions,
}) => {
  const navigate = useNavigate();

  const [projectsOptions, setProjectOptions] = useState<SelectOption<number>[]>([]);
  const [dataExtensionsOptions, setDataExtensionsOptions] = useState<SelectOption<number>[]>([]);

  const { data: projects, onSuccess: onProjectsSuccess, loading: projectsLoading } = useFetch(getActiveProjects);
  onProjectsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setProjectOptions(options);
  });

  const {
    data: dataExtensions,
    onSuccess: onDataExtensionsSuccess,
    loading: dataExtensionsLoading,
  } = useFetch(getActiveDataExtensions);
  onDataExtensionsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setDataExtensionsOptions(options);
  });

  useDidUpdate(() => {
    if (projects) {
      setSelectedProjects(() => projects.map((item) => item.id));
    }
  }, [projects]);

  useDidUpdate(() => {
    if (dataExtensions) {
      setSelectedDataExtensions(() => dataExtensions.map((item) => item.id));
    }
  }, [dataExtensions]);

  const projectsEmpty = projects?.length === 0 && !projectsLoading;
  const dataExtensionsEmpty = dataExtensions?.length === 0 && !dataExtensionsLoading;

  return (
    <div className="suppress-details">
      <div className={styles.projectSelect}>
        <Label text="Projects to suppress" isInputLabel />

        {projectsEmpty && (
          <div>
            <p className={styles.projectDescription}>
              You currently have no active projects. Click the button bellow, select the account and activate or create
              a new project.
            </p>

            <Button appearance="primary" onClick={() => navigate(ITERABLE_ACCOUNTS_LIST_PAGE.path)} btnSize="small">
              <Plus />
              Create first project
            </Button>
          </div>
        )}
        {!projectsEmpty && (
          <>
            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
            <Select
              name="projectIds"
              options={projectsOptions}
              isSearchable
              isMulti
              selectedOptionsKeys={selectedProjects}
              onChange={(options) => setSelectedProjects(options.map((item) => item.value))}
              searchPlaceholder="Search projects"
              asyncOptions={{ loading: projectsLoading }}
              disabled={projectsLoading}
              error={
                selectedProjects.length === 0 && !projectsLoading ? "At least one Iterable project is required" : ""
              }
            />
          </>
        )}
      </div>

      <div className={styles.projectSelect}>
        <Label text="Data extensions to suppress" isInputLabel />

        {dataExtensionsEmpty && (
          <>
            <div>
              <p className={styles.projectDescription}>
                You currently have no active data extensions. Click the button bellow, select the business unit and
                activate or create a new data extension.
              </p>

              <Button appearance="primary" onClick={() => navigate(BUSINESS_UNIT_DETAILS_PAGE.path)} btnSize="small">
                <Plus />
                Create first data extension
              </Button>
            </div>
          </>
        )}

        {!dataExtensionsEmpty && (
          <>
            <p className={styles.projectDescription}>Select data extensions to which suppresses will be added</p>
            <Select
              name="dataExtensionIds"
              options={dataExtensionsOptions}
              selectedOptionsKeys={selectedDataExtensions}
              onChange={(options) => setSelectedDataExtensions(options.map((item) => item.value))}
              isSearchable
              isMulti
              searchPlaceholder="Search data extensions"
              asyncOptions={{ loading: dataExtensionsLoading }}
              disabled={dataExtensionsLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};
