import { number, object, Schema, string } from "yup";

import {
  IterableData,
  SalesforceData,
  OptizmoExportData,
  SalesforceDataExtensionData,
  SalesforceListData,
} from "./optizmo-export.types";

/* ITERABLE initial values */

export const initialIterableListData = { id: 0, name: "" };

export const initialIterableData: IterableData = {
  esp: "iterable",
  accountId: 0,
  projectId: 0,
  fromList: initialIterableListData,
  toList: initialIterableListData,
};

/* SALESFORCE initial values */

export const initialSalesforceDataExtensionData: SalesforceDataExtensionData = {
  type: "dataExtension",
  customerKey: "",
  emailProperty: "",
  name: "",
};

export const initialSalesforceListData: SalesforceListData = {
  type: "list",
  name: "",
  id: 0,
};

export const initialSalesforceData: SalesforceData = {
  esp: "salesforce",
  businessUnitId: 0,

  fromListType: "dataExtension", // used only for conditional validation
  fromList: initialSalesforceDataExtensionData,

  toListType: "dataExtension", // used only for conditional validation
  toList: initialSalesforceDataExtensionData,
};

export const initialValues: OptizmoExportData = {
  companyId: 0,
  clientId: 0,
  offerId: 0,
  esp: "iterable",
  espData: initialIterableData,
};

/* ITERABLE validation schema */

export const iterableDataSchema: Schema<IterableData> = object().shape({
  esp: string().oneOf(["iterable"]).required("ESP is required"),
  accountId: number().min(1, "Account is required").required("Account is required"),
  projectId: number().min(1, "Project is required").required("Project is required"),
  fromList: object({
    id: number().min(1, "List is required").required("List is required"),
    name: string().required("List name is required"),
  }).required("From list is required"),
  toList: object({
    id: number().min(1, "List is required").required("List is required"),
    name: string().required("List name is required"),
  }).required("From list is required"),
});

/* SALESFORCE validation schema */

export const salesforceDataExtensionSchema: Schema<SalesforceDataExtensionData> = object().shape({
  type: string().oneOf(["dataExtension"]).required("Type is required"),
  customerKey: string().required("Customer key is required"),
  name: string().required("Name is required"),
  emailProperty: string().required("Email property is required"),
});

export const salesforceListSchema: Schema<SalesforceListData> = object().shape({
  type: string().oneOf(["list"]).required("Type is required"),
  name: string().required("Name is required"),
  id: number().min(0, "ID is required").required("ID is required"),
});

// eslint-disable-next-line
// @ts-ignore
export const salesforceDataSchema: Schema<SalesforceData> = object().shape({
  esp: string().oneOf(["salesforce"]).required("ESP is required"),
  businessUnitId: number().min(1, "Business unit is required").required("Business unit is required"),

  fromListType: string().oneOf(["dataExtension", "list"]),
  fromList: object()
    .when("fromListType", ([fromListType]) => {
      if (fromListType === "dataExtension") return salesforceDataExtensionSchema;
      return salesforceListSchema;
    })
    .required(),

  toListType: string().oneOf(["dataExtension", "list"]),
  toList: object()
    .when("toListType", ([fromListType]) => {
      if (fromListType === "dataExtension") return salesforceDataExtensionSchema;
      return salesforceListSchema;
    })
    .required(),
});

// eslint-disable-next-line
// @ts-ignore
export const validationSchema: Schema<OptizmoExportData> = object().shape({
  companyId: number().min(1, "Company is required").required("Company is required"),
  clientId: number().min(1, "Client is required").required("Client is required"),
  offerId: number().min(1, "Offer is required").required("Offer is required"),
  esp: string().oneOf(["iterable", "salesforce"]).required("ESP is required"),
  espData: object()
    .when("esp", ([esp]) => {
      if (esp === "salesforce") return salesforceDataSchema;
      return iterableDataSchema;
    })
    .required("ESP data is required"),
});
