import React from "react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useParams } from "react-router-dom";

import { BreadcrumbsOption, Container, TableError, TableNoContent } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination } from "hooks";
import { OfferModel } from "models";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { addItem, getInitialStorageFilters, getLinkPath, removeItem, updateItem } from "utils";
import { filtersOptions, TABLE_NAME, optizmoOffersColumns } from "./optizmo-offers.constants";
import { getClient, getCompany, getOffers } from "api";
import { DeleteResponse } from "api/api.types";
import { OPTIZMO_CLIENTS_LIST_PAGE, OPTIZMO_COMPANIES_LIST_PAGE } from "constants/routes.constants";
import { CreateOptizmoOffer, UpdateOptizmoOffer } from "pages/integrations/optizmo";
import { OptizmoOfferItem } from "./table-item/optizmo-offer-item";
import { Plus } from "assets";

import styles from "./optizmo-offers.module.scss";

export const OptizmoOffersPage: React.FC = () => {
  const { companyId, clientId } = useParams<{ companyId: string; clientId: string }>();
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create } = useItemManagement<OfferModel>();
  const { update } = useItemManagement<OfferModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("optizmo", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "accounts");

  const clientDetails = useFetch(getClient.setParams({ clientId: clientId || "" }), {
    disabled: !clientId,
  });

  const companyDetails = useFetch(getCompany.setParams({ companyId: companyId || "" }), {
    disabled: !companyId,
  });

  const { data, loading, refetch, error, setData } = useFetch(
    getOffers.setQueryParams({ ...query, search: query.search, clientId: clientId || "" }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (offer: OfferModel) => {
    update.setItem(offer);
  };

  const handleCreateAccountSuccess = (offer: OfferModel) => {
    if (data) addItem(offer, data, setData);
  };

  const handleUpdateAccountSuccess = (offer: OfferModel) => {
    if (data) updateItem(offer, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const backPath = getLinkPath(OPTIZMO_CLIENTS_LIST_PAGE.path, { companyId: companyId });

  const breadcrumbs: BreadcrumbsOption[] = [
    { name: "Optizmo companies", path: OPTIZMO_COMPANIES_LIST_PAGE.path },
    {
      // todo: change companyId to companyName
      name: companyDetails.data?.name || "",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      path: getLinkPath(OPTIZMO_CLIENTS_LIST_PAGE.path, { companyId: companyId }),
    },
    clientDetails.data?.name || "",
  ];

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="Optizmo offers"
        description="List of all Optizmo offers."
        navbarClassName="optizmo-offer-navbar"
        backPage={backPath}
        breadcrumbs={breadcrumbs}
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && (
              <Button onClick={create.open} className="add-optizmo-offer">
                <Plus /> Add Optizmo offer
              </Button>
            )}
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={optizmoOffersColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(offer, index) => (
              <OptizmoOfferItem
                index={index}
                offer={offer}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
              />
            )}
          />
          <CreateOptizmoOffer
            isOpen={create.isOpen}
            close={create.close}
            onCreateSuccess={handleCreateAccountSuccess}
            company={companyDetails.data}
          />
          <UpdateOptizmoOffer
            offer={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateAccountSuccess}
            company={companyDetails.data}
          />
        </div>
      </Container>
    </>
  );
};
