import React, { useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";

import { initialIterableListData } from "pages/export/optizmo-export.constants";
import { IterableData, OptizmoExportData } from "pages/export/optizmo-export.types";
import { getActiveAccounts, getIterableActiveLists, getIterableActiveProjects } from "api";

import styles from "../../optizmo-export.module.scss";

export const IterableSelector: React.FC = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<OptizmoExportData>();

  const [accountsOptions, setAccountOptions] = useState<SelectOption<number>[]>([]);
  const [projectsOptions, setProjectOptions] = useState<SelectOption<number>[]>([]);
  const [listsOptions, setListsOptions] = useState<SelectOption<number>[]>([]);

  const espData = values.espData as IterableData;

  const activeAccounts = useSubmit(getActiveAccounts);
  activeAccounts.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    setAccountOptions(options);
  });

  const activeProjects = useSubmit(getIterableActiveProjects);
  activeProjects.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((client) => ({
      label: client.name,
      value: client.id,
    }));
    setProjectOptions(options);
  });

  const activeLists = useSubmit(getIterableActiveLists);
  activeLists.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((client) => ({
      label: client.name,
      value: client.id,
    }));
    setListsOptions(options);
  });

  useDidMount(() => {
    activeAccounts.submit();
  });

  useDidUpdate(() => {
    setFieldValue("espData.projectId", 0);
    setFieldValue("espData.fromList", initialIterableListData);
    setFieldValue("espData.toList", initialIterableListData);

    if (espData.accountId) {
      activeProjects.submit({ queryParams: { accountId: espData.accountId.toString() } });
    }
  }, [espData.accountId]);

  useDidUpdate(() => {
    setFieldValue("espData.fromList", initialIterableListData);
    setFieldValue("espData.toList", initialIterableListData);

    if (espData.projectId) {
      activeLists.submit({ params: { projectId: espData.projectId.toString() } });
    }
  }, [espData.projectId]);

  const handleListChange = (listKey: "fromList" | "toList") => (option: SelectOption | null) => {
    // we only need to set the name - id is set automatically from FormField
    setFieldValue(`espData.${listKey}.name`, option?.label || "");
    setTimeout(() => setFieldTouched(`espData.${listKey}.name`, true));
  };

  const prepareListOptions = (direction: "from" | "to"): SelectOption<number>[] => {
    if (direction === "from") {
      if (espData.toList?.id) return listsOptions.filter((option) => option.value !== espData.toList.id);

      return listsOptions;
    }

    if (espData.fromList?.id) return listsOptions.filter((option) => option.value !== espData.fromList.id);
    return listsOptions;
  };

  return (
    <div style={{ marginTop: "26px" }}>
      <div>
        <div className={styles.row}>
          <div className={styles.step}>5</div>
          <div>
            <Label text="Accounts to suppress" isInputLabel />
            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
          </div>
        </div>
        <FormField
          type="select"
          name="espData.accountId"
          options={accountsOptions}
          isSearchable
          asyncOptions={{
            loading: activeAccounts.submitting,
          }}
          searchPlaceholder="Search accounts"
          isMulti={false}
        />
      </div>

      <div>
        <div className={styles.row}>
          <div className={styles.step}>6</div>
          <div>
            <Label text="Projects to suppress" isInputLabel />
            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
          </div>
        </div>
        <FormField
          type="select"
          name="espData.projectId"
          options={projectsOptions}
          isSearchable
          searchPlaceholder="Search clients"
          disabled={!espData?.accountId || activeAccounts.submitting}
          asyncOptions={{
            loading: activeProjects.submitting,
          }}
          isMulti={false}
        />
      </div>

      <div className={styles.selectGrid}>
        <div>
          <div className={styles.row}>
            <div className={styles.step}>7</div>
            <div>
              <Label text="From list" isInputLabel />
              <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
            </div>
          </div>
          <FormField
            type="select"
            name="espData.fromList.id"
            options={prepareListOptions("from")}
            onChange={handleListChange("fromList")}
            isSearchable
            searchPlaceholder="Search lists"
            disabled={!espData?.accountId || !espData?.projectId || activeProjects.submitting}
            asyncOptions={{
              loading: activeProjects.submitting,
            }}
            isMulti={false}
          />
        </div>

        <div>
          <div className={styles.row}>
            <div className={styles.step}>8</div>
            <div>
              <Label text="To list" isInputLabel />
              <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
            </div>
          </div>
          <FormField
            type="select"
            name="espData.toList.id"
            onChange={handleListChange("toList")}
            options={prepareListOptions("to")}
            isSearchable
            searchPlaceholder="Search lists"
            disabled={!espData?.accountId || !espData?.projectId || activeProjects.submitting}
            asyncOptions={{
              loading: activeProjects.submitting,
            }}
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};
