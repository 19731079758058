import { client } from "api/client.api";
import { OfferModel, List } from "models";
import { OfferData, UpdateOfferData } from "./offers.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";

export const createOffer = client.createRequest<OfferModel, OfferData>()({
  method: "POST",
  endpoint: "/offers",
});

export const getOffers = client.createRequest<
  List<OfferModel>,
  null,
  ServerErrorType,
  BaseSearchQuery & BasePaginationQuery & { clientId: string }
>()({
  method: "GET",
  endpoint: "/offers",
  options: { timeout: 0 },
});

export const getActiveOffers = client.createRequest<
  OfferModel[],
  null,
  ServerErrorType,
  Partial<BaseSearchQuery & BasePaginationQuery & { clientId: string; optizmo?: string }>
>()({
  method: "GET",
  endpoint: "/offers/active",
  options: { timeout: 0 },
});

export const getOffer = client.createRequest<OfferModel, null, ServerErrorType>()({
  method: "GET",
  endpoint: "/offers/:offerId",
  options: { timeout: 0 },
});

export const updateOffer = client.createRequest<OfferModel, UpdateOfferData>()({
  method: "PUT",
  endpoint: "/offers/:offerId",
});

export const deleteOffer = client.createRequest<DeleteResponse>()({
  endpoint: "/offers/:offerId",
  method: "DELETE",
});
